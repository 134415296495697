/**
 * @author Alessandro Frenna
 * @description router component for the single page application
 */
import { Role } from "app/auth";
import Error from "app/pages/Error";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute allowAccessTo={[Role.ALL]} exact path="/" component={() => <Redirect to="/fields" />} />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        path="/settings"
        component={React.lazy(() => import("../pages/settings/General"))}
      />
      {/* EVAPOTRANSPIRATION ROUTE */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        path="/evapotranspiration"
        exact
        component={React.lazy(() => import("../pages/evapotranspiration/Evapotranspiration"))}
      />
      {/* ACTIVITIES ROUTE */}
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        path="/activities"
        exact
        component={React.lazy(() => import("../pages/activities/Activities"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        path="/activities/add"
        exact
        component={React.lazy(() => import("../pages/activities/ActivityForm"))}
      />
      <PrivateRoute
        exact
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        path="/activities/:id/edit"
        component={React.lazy(() => import("../pages/activities/ActivityForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        path="/activities/:id"
        exact
        component={React.lazy(() => import("../pages/activities/ActivityView"))}
      />
      {/* API KEY ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        path="/api-keys"
        exact
        component={React.lazy(() => import("../pages/clientApikeys/ClientApiKeysTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        path="/api-keys/add"
        exact
        component={React.lazy(() => import("../pages/clientApikeys/ClientApiKeyForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        path="/api-keys/:id"
        exact
        component={React.lazy(() => import("../pages/clientApikeys/ClientApiKeyView"))}
      />
      {/* PROFILE COMPANY ROUTES */}
      <PrivateRoute
        exact
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        path="/profile"
        component={React.lazy(() => import("../pages/profile/BusinessProfileView"))}
      />
      <PrivateRoute
        exact
        allowAccessTo={[Role.ADMIN]}
        path="/profile/edit"
        checkRolesStrictly
        component={React.lazy(() => import("../pages/profile/BusinessProfileForm"))}
      />
      {/*COMPANIES ROUTES */}
      <PrivateRoute
        exact
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        path="/companies"
        component={React.lazy(() => import("../pages/companies/CompaniesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/companies/add"
        component={React.lazy(() => import("../pages/companies/CompanyForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        path="/companies/:id"
        exact
        component={React.lazy(() => import("../pages/companies/CompanyView"))}
      />
      <PrivateRoute
        exact
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        path="/companies/:id/edit"
        component={React.lazy(() => import("../pages/companies/CompanyForm"))}
      />
      {/* USERS ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        exact
        path="/users"
        component={React.lazy(() => import("../pages/users/UsersTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        exact
        path="/users/add"
        component={React.lazy(() => import("../pages/users/UserForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        exact
        path="/users/:id/edit"
        component={React.lazy(() => import("../pages/users/UserForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        exact
        path="/users/:id"
        component={React.lazy(() => import("../pages/users/UserView"))}
      />
      {/* TENANTS ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/tenants"
        component={React.lazy(() => import("../pages/tenants/TenantsTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/tenants/add"
        component={React.lazy(() => import("../pages/tenants/TenantForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/tenants/:id/edit"
        component={React.lazy(() => import("../pages/tenants/TenantForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/tenants/:id"
        component={React.lazy(() => import("../pages/tenants/TenantView"))}
      />
      {/* JOBS ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/scheduled-jobs"
        component={React.lazy(() => import("../pages/jobs/ScheduledJobsTable"))}
      />
      {/* IOT DEVICES ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        exact
        path="/devices"
        component={React.lazy(() => import("../pages/iot/devices/DevicesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        exact
        path="/devices/add"
        component={React.lazy(() => import("../pages/iot/devices/CreateDeviceForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        exact
        path="/devices/:id"
        component={React.lazy(() => import("../pages/iot/devices/Device"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        exact
        path="/devices/:id/edit"
        component={React.lazy(() => import("../pages/iot/devices/UpdateDeviceForm"))}
      />
      {/* IOT WIRELESS DEVICES ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        exact
        path="/wireless-devices"
        component={React.lazy(() => import("../pages/iot/devices/wireless/WirelessDevicesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        exact
        path="/wireless-devices/add"
        component={React.lazy(() => import("../pages/iot/devices/wireless/WirelessDeviceForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        exact
        path="/wireless-devices/:id"
        component={React.lazy(() => import("../pages/iot/devices/wireless/WirelessDeviceView"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN]}
        checkRolesStrictly
        exact
        path="/wireless-devices/:id/edit"
        component={React.lazy(() => import("../pages/iot/devices/wireless/WirelessDeviceForm"))}
      />
      {/* IOT SENSORS ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        exact
        path="/sensors"
        component={React.lazy(() => import("../pages/iot/sensors/SensorsTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        exact
        path="/sensors/:id"
        component={React.lazy(() => import("../pages/iot/sensors/SensorView"))}
      />
      {/* IOT DEVICE DEFINITIONS ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/devices"
        component={React.lazy(() => import("../pages/iot/definitions/devices/DevicesDefinitionsTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/devices/add"
        component={React.lazy(() => import("../pages/iot/definitions/devices/DeviceDefinitionForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/devices/:id"
        component={React.lazy(() => import("../pages/iot/definitions/devices/DeviceDefinitionsView"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/devices/:id/edit"
        component={React.lazy(() => import("../pages/iot/definitions/devices/DeviceDefinitionForm"))}
      />
      {/* IOT SENSORS ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/sensors"
        component={React.lazy(() => import("../pages/iot/definitions/sensors/SensorsDefinitionsTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/sensors/add"
        component={React.lazy(() => import("../pages/iot/definitions/sensors/SensorDefinitionForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/sensors/:id/edit"
        component={React.lazy(() => import("../pages/iot/definitions/sensors/SensorDefinitionForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/sensors/:id"
        component={React.lazy(() => import("../pages/iot/definitions/sensors/SensorDefinitionsView"))}
      />
      {/* IOT VARIABLES ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/variables"
        component={React.lazy(() => import("../pages/iot/definitions/variables/VariablesDefinitionsTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/variables/add"
        component={React.lazy(() => import("../pages/iot/definitions/variables/VariableDefinitionForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/variables/:id/edit"
        component={React.lazy(() => import("../pages/iot/definitions/variables/VariableDefinitionForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/variables/:id"
        component={React.lazy(() => import("../pages/iot/definitions/variables/VariableDefinitionsView"))}
      />
      {/* PROFILE ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        exact
        path="/profile/change-password"
        component={React.lazy(() => import("../pages/users/ChangePassword"))}
      />
      {/* GROUPS ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER, Role.VIEWER]}
        path="/groups"
        exact
        component={React.lazy(() => import("../pages/groups/GroupsTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        path="/groups/add"
        exact
        component={React.lazy(() => import("../pages/groups/GroupForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/groups/:id/edit"
        component={React.lazy(() => import("../pages/groups/GroupForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER, Role.VIEWER]}
        path="/groups/:id"
        exact
        component={React.lazy(() => import("../pages/groups/GroupView"))}
      />
      {/* FIELDS ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        path="/fields"
        exact
        component={React.lazy(() => import("../pages/fields/Fields"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        path="/fields/add"
        exact
        component={React.lazy(() => import("../pages/fields/FieldForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        path="/fields/add/active-culture"
        exact
        component={React.lazy(() => import("../pages/fields/FieldCultureForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/fields/:id/edit"
        component={React.lazy(() => import("../pages/fields/FieldForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/fields/:id/edit/active-culture"
        component={React.lazy(() => import("../pages/fields/FieldCultureForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        path="/fields/:id"
        exact
        component={React.lazy(() => import("../pages/fields/Field"))}
      />
      {/* FIRMWARES ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        path="/firmwares"
        exact
        component={React.lazy(() => import("../pages/firmwares/FirmwaresTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        path="/firmwares/:id"
        exact
        component={React.lazy(() => import("../pages/firmwares/FirmwareView"))}
      />
      {/* GRANULOMETRIES ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/granulometries"
        component={React.lazy(() => import("../pages/fields/granulometries/SoilGranulometriesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/granulometries/add"
        component={React.lazy(() => import("../pages/fields/granulometries/SoilGranulometryForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/granulometries/:id/edit"
        component={React.lazy(() => import("../pages/fields/granulometries/SoilGranulometryForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        exact
        checkRolesStrictly
        path="/granulometries/:id"
        component={React.lazy(() => import("../pages/fields/granulometries/SoilGranulometryView"))}
      />
      {/* IRRIGATION METHODS ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/irrigation-methods"
        component={React.lazy(() => import("../pages/fields/irrigation/methods/IrrigationMethodsTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/irrigation-methods/add"
        component={React.lazy(() => import("../pages/fields/irrigation/methods/IrrigationMethodForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/irrigation-methods/:id/edit"
        component={React.lazy(() => import("../pages/fields/irrigation/methods/IrrigationMethodForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/irrigation-methods/:id"
        component={React.lazy(() => import("../pages/fields/irrigation/methods/IrrigationMethodView"))}
      />
      {/* IRRIGATION SOUCRCES ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/irrigation-sources"
        component={React.lazy(() => import("../pages/fields/irrigation/sources/IrrigationSourcesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/irrigation-sources/add"
        component={React.lazy(() => import("../pages/fields/irrigation/sources/IrrigationSourceForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/irrigation-sources/:id/edit"
        component={React.lazy(() => import("../pages/fields/irrigation/sources/IrrigationSourceForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/irrigation-sources/:id"
        component={React.lazy(() => import("../pages/fields/irrigation/sources/IrrigationSourceView"))}
      />
      {/* COVERAGE TYPES ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/coverage-types"
        component={React.lazy(() => import("../pages/fields/coverages/CoverageTypesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/coverage-types/add"
        component={React.lazy(() => import("../pages/fields/coverages/CoverageTypeForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/coverage-types/:id/edit"
        component={React.lazy(() => import("../pages/fields/coverages/CoverageTypeForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/coverage-types/:id"
        component={React.lazy(() => import("../pages/fields/coverages/CoverageTypeView"))}
      />
      {/* CULTURE TYPES ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/culture-types"
        component={React.lazy(() => import("../pages/fields/cultureTypes/CultureTypesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/culture-types/add"
        component={React.lazy(() => import("../pages/fields/cultureTypes/CultureTypeForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/culture-types/:id/edit"
        component={React.lazy(() => import("../pages/fields/cultureTypes/CultureTypeForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/culture-types/:id"
        component={React.lazy(() => import("../pages/fields/cultureTypes/CultureTypeView"))}
      />
      {/* FIELD TYPES ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/field-types"
        component={React.lazy(() => import("../pages/fields/types/FieldTypesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/field-types/add"
        component={React.lazy(() => import("../pages/fields/types/FieldTypeForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/field-types/:id/edit"
        component={React.lazy(() => import("../pages/fields/types/FieldTypeForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/field-types/:id"
        component={React.lazy(() => import("../pages/fields/types/FieldTypeView"))}
      />
      {/* AGEA CULTURES DEFINITIONS */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/cultures"
        component={React.lazy(() => import("../pages/agea/cultures/AgeaCuluresTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/cultures/add"
        component={React.lazy(() => import("../pages/agea/cultures/AgeaCultureForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/cultures/:id/edit"
        component={React.lazy(() => import("../pages/agea/cultures/AgeaCultureForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/cultures/:id"
        component={React.lazy(() => import("../pages/agea/cultures/AgeaCultureView"))}
      />
      {/* AGEA VARIETIES DEFINITIONS */}
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/varieties"
        component={React.lazy(() => import("../pages/agea/varieties/AgeaCultureVarietiesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/varieties/add"
        component={React.lazy(() => import("../pages/agea/varieties/AgeaCultureVarietyForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/varieties/:id/edit"
        component={React.lazy(() => import("../pages/agea/varieties/AgeaCultureVarietyForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.SUPERADMIN]}
        checkRolesStrictly
        exact
        path="/definitions/varieties/:id"
        component={React.lazy(() => import("../pages/agea/varieties/AgeaCultureVarietyView"))}
      />
      {/* CULTURE MANAGEMENT ROUTES */}
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        checkRolesStrictly
        exact
        path="/management/cultures"
        component={React.lazy(() => import("../pages/cultures/CulturesTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/management/cultures/add"
        component={React.lazy(() => import("../pages/fields/FieldCultureForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/management/cultures/:id/edit"
        component={React.lazy(() => import("../pages/fields/FieldCultureForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        checkRolesStrictly
        exact
        path="/management/cultures/:id"
        component={React.lazy(() => import("../pages/cultures/CultureView"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        checkRolesStrictly
        exact
        path="/management/fields"
        component={React.lazy(() => import("../pages/fields/FieldsTable"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/management/fields/add"
        component={React.lazy(() => import("../pages/fields/FieldForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/management/fields/add/active-culture"
        component={React.lazy(() => import("../pages/fields/FieldCultureForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/management/fields/:id/edit"
        component={React.lazy(() => import("../pages/fields/FieldForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/management/fields/:id/edit/active-culture"
        component={React.lazy(() => import("../pages/fields/FieldCultureForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        checkRolesStrictly
        exact
        path="/management/fields/:id"
        component={React.lazy(() => import("../pages/fields/FieldView"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        path="/management/fields/:fieldId/cultures/add"
        exact
        component={React.lazy(() => import("../pages/fields/FieldCultureForm"))}
      />
      <PrivateRoute
        allowAccessTo={[Role.ADMIN, Role.MANAGER]}
        exact
        path="/management/fields/:fieldId/cultures/:id/edit"
        component={React.lazy(() => import("../pages/fields/FieldCultureForm"))}
      />
      {/* NOTIFICATION ROUTE */}
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        exact
        path="/notifications"
        component={React.lazy(() => import("../pages/Notifications"))}
      />
      {/* UTILITY ROUTES */}
      <PrivateRoute exact path="/error" allowAccessTo={[Role.ALL]} component={() => <Error />} />
      <PrivateRoute
        allowAccessTo={[Role.ALL]}
        path="*"
        component={() => <Redirect to={{ pathname: "/error", state: 404 }} />}
      />
    </Switch>
  );
};
